"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.localStoreHelper = void 0;
var localStoreHelper = /** @class */ (function () {
    function localStoreHelper() {
    }
    localStoreHelper.newGuid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    localStoreHelper.addModelResult = function (resObj) {
        if (localStorage) {
            if (!localStorage.getItem('ANEMI_Results')) {
                resObj.RunName = 'Baseline';
                localStorage.setItem('ANEMI_Results', JSON.stringify({ graphObj: [resObj], LastRun: 0 }));
            }
            else {
                var returnObj = JSON.parse(localStorage.getItem('ANEMI_Results'));
                var numberRuns = returnObj.graphObj.length;
                if (numberRuns === 4) { //it has 3 already runs and removes the first run (not the basline) and adds the new to the end
                    returnObj.graphObj.splice(1, 1);
                }
                returnObj.graphObj.push(__assign({ RunName: 'Run ' + (returnObj.LastRun + 1) }, resObj));
                returnObj.LastRun++;
                localStorage.setItem('ANEMI_Results', JSON.stringify(returnObj));
            }
        }
    };
    localStoreHelper.readModelResults = function () {
        if (localStorage) {
            var returnObj = localStorage.getItem('ANEMI_Results');
            if (returnObj) {
                return JSON.parse(returnObj).graphObj;
            }
        }
        return null;
    };
    localStoreHelper.addModelHistory = function (scenarioId, variableValues, scenarioName, listVariables) {
        if (!variableValues)
            throw new Error('Exception message');
        if (localStorage) {
            var returnObj = JSON.parse(localStorage.getItem('ANEMI_History'));
            if (!returnObj) {
                returnObj = { ListHistory: [], LastHistory: 0 };
            }
            returnObj.ListHistory.unshift({
                runName: "Run: " + (returnObj.LastHistory + 1) + " (" + scenarioName + ")",
                scenarioId: scenarioId,
                variablesValues: variableValues,
                guid: this.newGuid(),
                listVariables: listVariables
            });
            returnObj.LastHistory++;
            localStorage.setItem('ANEMI_History', JSON.stringify(returnObj));
        }
    };
    localStoreHelper.readModelHistory = function () {
        if (localStorage) {
            var returnObj = localStorage.getItem('ANEMI_History');
            if (returnObj) {
                return JSON.parse(returnObj).ListHistory;
            }
        }
        return null;
    };
    localStoreHelper.readModelHistoryFromList = function (guid) {
        if (localStorage) {
            var returnObj = localStorage.getItem('ANEMI_History');
            if (returnObj) {
                return JSON.parse(returnObj).ListHistory.find(function (e) { return e.guid === guid; });
            }
        }
        return null;
    };
    localStoreHelper.clearHistory = function () {
        if (localStorage) {
            localStorage.removeItem('ANEMI_History');
        }
        return [];
    };
    localStoreHelper.clearResultGraphs = function (keepBaseline) {
        if (keepBaseline === void 0) { keepBaseline = false; }
        if (localStorage) {
            if (keepBaseline) {
                if (localStorage.getItem('ANEMI_Results')) {
                    var returnObj = JSON.parse(localStorage.getItem('ANEMI_Results'));
                    var resObj = returnObj.graphObj.where(function (e) { return e.RunName === 'Baseline'; });
                    if (resObj) {
                        localStorage.removeItem('ANEMI_Results');
                        localStorage.setItem('ANEMI_Results', JSON.stringify({ graphObj: [resObj], LastRun: 0 }));
                    }
                }
            }
            else
                localStorage.removeItem('ANEMI_Results');
        }
    };
    return localStoreHelper;
}());
exports.localStoreHelper = localStoreHelper;
