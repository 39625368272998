"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Graphs = void 0;
var React = require("react");
var Highcharts = require("highcharts");
var highcharts_react_official_1 = require("highcharts-react-official");
var LocalstoreUtil_1 = require("./LocalstoreUtil");
var highcharts_more_1 = require("highcharts/highcharts-more");
var exporting_1 = require("highcharts/modules/exporting");
var export_data_1 = require("highcharts/modules/export-data");
var CircularProgress_1 = require("@material-ui/core/CircularProgress");
var $ = require("jquery");
highcharts_more_1.default(Highcharts);
exporting_1.default(Highcharts);
export_data_1.default(Highcharts);
var H = Highcharts;
H.addEvent(H.Chart, 'render', function (e) {
    var divWrapId = 'highcharts-data-table-wrap-' + e.target.container.id;
    $("#" + divWrapId).remove();
});
Highcharts.Chart.prototype.viewData = function () {
    var divParent = $(this.renderTo).parent().parent();
    var divId = this.container.id + '-data-table';
    var divWrapId = 'highcharts-data-table-wrap-' + this.container.id;
    $("#" + divWrapId).remove();
    var buttonClose = "<button type=\"submit\" onclick=\"javscript:$('#" + divWrapId + "').remove();\"  class=\"btn btn-info\" style=\"margin-top: 5px;\">Hide table</button>";
    var divWrap = $(document.createElement('div'));
    divWrap.attr('id', divWrapId);
    var div = document.createElement('div');
    div.className = 'highcharts-data-table';
    divWrap.append(buttonClose);
    divWrap.append(div);
    div.innerHTML = this.getTable();
    this.insertedTable = true;
    div.id = divId;
    divParent.append(divWrap);
};
Highcharts.defaultOptions.credits.enabled = false;
var colorSeries = ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
var indexColor = {};
var dashStyleSeries = ['Solid', 'Dash', 'ShortDot', 'ShortDashDot', 'ShortDashDotDot', 'DashDot', 'Dot', 'LongDash', 'LongDashDot', 'LongDashDotDot', 'ShortDash'];
var StringFormat = function (str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return str.replace(/{(\d+)}/g, function (match, index) { return args[index] || ''; });
};
var Graphs = /** @class */ (function (_super) {
    __extends(Graphs, _super);
    function Graphs(props) {
        var _this = _super.call(this, props) || this;
        _this.isLoading = false;
        _this.state = {
            chartOptions: null,
            divSummaryGraph: null,
            listIndividualGraphs: null,
            runName: ''
        };
        return _this;
    }
    Graphs.prototype.componentDidMount = function () {
        this.runModel();
        indexColor = {};
    };
    Graphs.prototype.componentDidUpdate = function () {
        if (!this.isLoading) {
            this.runModel();
            indexColor = {};
        }
    };
    Graphs.prototype.getColor = function (graphId) {
        if (!indexColor[graphId]) {
            var count = Object.keys(indexColor).length;
            indexColor[graphId] = colorSeries[count];
        }
        return indexColor[graphId];
    };
    //after created event
    Graphs.prototype.afterChartCreated = function (c) {
        c.reflow();
    };
    Graphs.prototype.createGraphObject = function (options, indexSeries, colorString) {
        return {
            chart: {
                type: 'spline',
                zoomType: 'xy',
            },
            title: {
                text: options.Title
            },
            subtitle: {
                text: 'Source: ANEMI'
            },
            xAxis: {
                categories: options.xValues,
                tickInterval: 10
            },
            yAxis: {
                title: {
                    text: options.yAxis
                }
            },
            tooltip: {
                shared: true,
                valueDecimals: 2
            },
            legend: {
                symbolWidth: 50
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    },
                    enableMouseTracking: false
                },
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 3
                }
            },
            series: [{
                    name: options.SeriesName,
                    data: options.yValues,
                    dashStyle: dashStyleSeries[indexSeries],
                    color: colorString
                }]
        };
    };
    Graphs.prototype.renderGraphs = function () {
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: "card" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "d-flex flex-wrap" },
                        React.createElement("div", null,
                            React.createElement("h4", { className: "card-title", style: { fontWeight: 600, paddingTop: 20 } }, "Summary of model performance"),
                            React.createElement("div", { className: "card-subtitle" }, "Combined presentation of model performance"),
                            React.createElement("p", null)),
                        React.createElement("div", { className: "ml-auto" },
                            React.createElement("div", { className: "d-flex no-block align-items-center justify-content-center" },
                                React.createElement("div", null,
                                    React.createElement("h6", { className: "text-success" },
                                        React.createElement("i", { className: "font-10 mr-2" }))),
                                React.createElement("div", { className: "ml-3" },
                                    React.createElement("h6", { className: "text-info" },
                                        React.createElement("i", { className: "font-10 mr-2" }))))))),
                React.createElement("div", { className: "card-body" },
                    React.createElement("figure", { className: "highcharts-figure" }, this.state.divSummaryGraph ?
                        React.createElement("div", { className: 'wrapGraphSummary' },
                            React.createElement("div", { className: 'chartClass' },
                                React.createElement(highcharts_react_official_1.default, { highcharts: Highcharts, containerProps: { style: { height: "100%" } }, options: this.state.divSummaryGraph, callback: this.afterChartCreated, updateArgs: [true, true, true] }))) : React.createElement(CircularProgress_1.default, { disableShrink: true }))),
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "d-flex flex-wrap" },
                        React.createElement("div", null,
                            React.createElement("h4", { className: "card-title", style: { fontWeight: 600, paddingTop: 20 } }, "Individual metric performance"),
                            React.createElement("div", { className: "card-subtitle" }, "Representative model variables."),
                            React.createElement("p", null)),
                        React.createElement("div", { className: "ml-auto" },
                            React.createElement("div", { className: "d-flex no-block align-items-center justify-content-center" },
                                React.createElement("div", null,
                                    React.createElement("h6", { className: "text-success" },
                                        React.createElement("i", { className: "font-10 mr-2" }))),
                                React.createElement("div", { className: "ml-3" },
                                    React.createElement("h6", { className: "text-info" },
                                        React.createElement("i", { className: "font-10 mr-2" }))))))),
                React.createElement("div", { className: "card-body" },
                    React.createElement("figure", { className: "highcharts-figure" }, this.state.listIndividualGraphs ? this.state.listIndividualGraphs : React.createElement(CircularProgress_1.default, { disableShrink: true })))),
            ";");
    };
    Graphs.prototype.render = function () {
        return this.renderGraphs();
    };
    Graphs.prototype.runModel = function () {
        var _this = this;
        this.isLoading = true;
        this.setState({
            listIndividualGraphs: null,
            divSummaryGraph: null
        });
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ scenarioId: this.props.scenarioId, variableValue: this.props.variableValue ? this.props.variableValue : null })
        };
        var listGraphs = [];
        var summaryObj = this.newObjSummary();
        var tmpRunName = '';
        fetch('/api/runmodel', requestOptions)
            .then(function (res) { return res.json(); })
            .then(function (result) {
            var ResultsObjId = result.resultsObject.id;
            sessionStorage.setItem('ResultObjectId', ResultsObjId);
            var restObj = {
                graphs: JSON.parse(result.resultsObject.resultsJson),
                variables: _this.props.variableValue,
                scenarioId: _this.props.scenarioId
            };
            //if (!this.props.variableValue)
            //    localStoreHelper.addModelResult(restObj, true);
            //else
            //    localStoreHelper.addModelResult(restObj);
            if (_this.props.add2Results)
                LocalstoreUtil_1.localStoreHelper.addModelResult(restObj);
            var listResults = LocalstoreUtil_1.localStoreHelper.readModelResults();
            if (listResults) {
                var obj_1 = [];
                listResults.forEach(function (res, indexRes) {
                    var cntyAxis = 0;
                    if (res.graphs)
                        res.graphs.forEach(function (g, indexGraph) {
                            var colorString = _this.getColor(g.Graph.GraphId);
                            if (g.FlagSummaryGraph && indexRes === listResults.length - 1) {
                                if (!summaryObj.yAxis)
                                    summaryObj.yAxis = [];
                                tmpRunName = res.RunName;
                                summaryObj.yAxis.push({
                                    labels: {
                                        useHTML: false,
                                        formatter: function () {
                                            return "<b>" + this.value + "</b>";
                                        },
                                        style: {
                                            color: colorString,
                                            fontSize: "12px",
                                        }
                                    },
                                    title: {
                                        text: g.Graph.Units,
                                        style: {
                                            color: colorString,
                                            fontSize: "12px",
                                        }
                                    },
                                    opposite: false
                                });
                                summaryObj.series.push({
                                    name: g.Graph.GraphName + " (" + tmpRunName + ")",
                                    type: 'spline',
                                    yAxis: cntyAxis,
                                    data: g.Value,
                                    tooltip: {
                                        valueSuffix: " " + g.Graph.Units,
                                        //headerFormat: '<b>{point.key}</b><br/>',
                                        //pointFormat: '{series.name}: {point.y: %.2f}<br/>',
                                        //shared: true
                                        valueDecimals: 2
                                    },
                                    lineWidth: 5,
                                    color: colorString //Highcharts.getOptions().colors[cntyAxis]
                                });
                                cntyAxis++;
                                if (!summaryObj.xAxis)
                                    summaryObj.xAxis = [{
                                            categories: g.Year,
                                            crosshair: true,
                                            tickInterval: 10
                                        }];
                            }
                            if (indexRes === 0) {
                                obj_1[indexGraph] = _this.createGraphObject({
                                    Title: g.Graph.GraphName,
                                    yAxis: g.Graph.Units,
                                    yValues: g.Value,
                                    xValues: g.Year,
                                    SeriesName: res.RunName
                                }, 0, colorString);
                            }
                            else {
                                var seriesCnt = obj_1[indexGraph].series.length;
                                obj_1[indexGraph].series.push({
                                    name: res.RunName,
                                    data: g.Value,
                                    dashStyle: dashStyleSeries[seriesCnt],
                                    color: colorString
                                });
                            }
                        });
                });
                obj_1.forEach(function (g, index) {
                    listGraphs.push(React.createElement("div", { className: 'divWrap', key: "div_wrap_chart_" + index },
                        React.createElement("div", { className: 'chartClass', key: "div_chart_" + index },
                            React.createElement(highcharts_react_official_1.default, { highcharts: Highcharts, containerProps: { style: { height: "100%" } }, key: "chart_" + index, options: g, callback: _this.afterChartCreated }))));
                });
            }
            _this.setState({
                listIndividualGraphs: listGraphs,
                divSummaryGraph: summaryObj,
                runName: tmpRunName
            });
            _this.isLoading = false;
        }, function (error) {
            _this.setState({
                listIndividualGraphs: null,
                divSummaryGraph: null,
                runName: ''
            });
        });
    };
    Graphs.prototype.newObjSummary = function () {
        return {
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    marker: {
                        enabled: false
                    },
                    lineWidth: 5
                }
            },
            xAxis: null,
            chart: {
                alignTicks: true,
                zoomType: 'xy'
            },
            title: {
                text: 'Summary',
                align: 'center'
            },
            subtitle: {
                text: 'Source: ANEMI',
                align: 'center'
            },
            yAxis: [],
            tooltip: {
                valueDecimals: 2,
                //headerFormat: '<b>{point.key}</b><br/>',
                //pointFormat: '{series.name}: {point.y: %.2f}<br/>',
                shared: true
            },
            //legend: {
            //    layout: 'vertical',
            //    align: 'left',
            //    x: 80,
            //    verticalAlign: 'top',
            //    y: 55,
            //    floating: true,
            //    backgroundColor:
            //        Highcharts.defaultOptions.legend.backgroundColor || // theme
            //        'rgba(255,255,255,0.25)'
            //},
            series: [],
            responsive: {
                rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            //legend: {
                            //    floating: false,
                            //    layout: 'horizontal',
                            //    align: 'center',
                            //    verticalAlign: 'bottom',
                            //    x: 0,
                            //    y: 0
                            //},
                            yAxis: [{
                                    labels: {
                                        align: 'right',
                                        x: 0,
                                        y: -6
                                    },
                                    showLastLabel: false
                                }, {
                                    labels: {
                                        align: 'left',
                                        x: 0,
                                        y: -6
                                    },
                                    showLastLabel: false
                                }, {
                                    visible: false
                                }]
                        }
                    }]
            }
        };
    };
    ;
    return Graphs;
}(React.Component));
exports.Graphs = Graphs;
