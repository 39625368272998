"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Variables = void 0;
var React = require("react");
var reactstrap_1 = require("reactstrap");
var core_1 = require("@material-ui/core");
var Switch_1 = require("@material-ui/core/Switch");
var FormControlLabel_1 = require("@material-ui/core/FormControlLabel");
var jquery_1 = require("jquery");
var Graphs_1 = require("./Graphs");
var ReactDOM = require("react-dom");
var LocalstoreUtil_1 = require("./LocalstoreUtil");
var GridHistory_1 = require("./GridHistory");
function valuetext(value) {
    return value + "\u00B0C";
}
var decimalPlaces = 2;
var StringFormat = function (str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return str.replace(/{(\d+)}/g, function (match, index) { return args[index] || ''; });
};
var variableValueBaseline = {};
var variableMarksState;
var selectedScenario;
var Variables = /** @class */ (function (_super) {
    __extends(Variables, _super);
    function Variables(props) {
        var _this = _super.call(this, props) || this;
        _this.isLoading = false;
        _this.state = {
            switches: {},
            listVariables: null,
            divVarDescription: null,
            divListHistoricalRunsTable: null,
            clearHistory: false,
            variableValueState: {}
        };
        _this.myRef = [];
        selectedScenario = _this.props.scenario;
        return _this;
    }
    Variables.prototype.componentDidMount = function () {
        this.fetchData();
    };
    Variables.prototype.componentDidUpdate = function () {
        if (selectedScenario.scenarioId != this.props.scenario.scenarioId || this.props.forceResetProp) {
            if (!this.isLoading) {
                this.fetchData();
                selectedScenario = this.props.scenario;
                this.props.forceResetFunc(false);
            }
        }
    };
    Variables.prototype.fetchData = function () {
        var _this = this;
        this.isLoading = true;
        fetch("/api/variables/" + this.props.scenarioId).then(function (res) { return res.json(); })
            .then(function (listVars) {
            var listDivDescription = [];
            var switchTemp = {};
            var variableValueTemp = {};
            variableMarksState = {};
            _this.setState({
                listVariables: [],
                switches: {},
                divVarDescription: [],
                variableValueState: {}
            });
            listVars.forEach(function (variable) {
                variableValueTemp[variable.variableId] = variable.initialValue;
                variableValueBaseline[variable.variableId] = variable.initialValue;
                switch (variable.type) {
                    case "Switch":
                        switchTemp[variable.variableId] = false;
                    case "Dropdown":
                    case "Continuous":
                        var marks_1 = [];
                        if (variable.dropdownText) {
                            var dropdownValues = variable.dropdownValues.split(';');
                            var dropdownText_1 = variable.dropdownText.split(';');
                            dropdownValues.map(function (dropdownValue, index) {
                                marks_1.push({
                                    value: dropdownValue,
                                    label: jquery_1.trim(dropdownText_1[index])
                                });
                            });
                        }
                        else {
                            marks_1 = [{
                                    value: variable.mininum,
                                    label: variable.mininum
                                },
                                {
                                    value: variable.maximum,
                                    label: variable.maximum
                                }
                            ];
                        }
                        variableMarksState[variable.variableId] = marks_1;
                        break;
                }
                listDivDescription.push(React.createElement("div", { key: variable.variableId, className: "alert alert-success", role: "alert", ref: function (ref) { _this.myRef[variable.variableId] = ref; } },
                    React.createElement("h4", { className: "alert-heading" }, variable.nameUi),
                    React.createElement("p", { style: { fontSize: 16 }, dangerouslySetInnerHTML: { __html: variable.description } })));
            });
            _this.setState({
                listVariables: listVars,
                divVarDescription: listDivDescription,
                divListHistoricalRunsTable: null,
                variableValueState: variableValueTemp,
                switches: switchTemp
            });
            LocalstoreUtil_1.localStoreHelper.clearHistory();
            LocalstoreUtil_1.localStoreHelper.clearResultGraphs();
            _this.setState({ clearHistory: true });
            renderGraphs(_this.props.scenarioId, variableValueTemp, _this.props.scenario.scenarioName, listVars, false, true);
            _this.isLoading = false;
        });
    };
    Variables.prototype.renderForm = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(reactstrap_1.Row, null,
                React.createElement(reactstrap_1.Card, null,
                    React.createElement(reactstrap_1.CardBody, null,
                        React.createElement("h4", { className: "card-title", style: { fontWeight: 600 } }, this.props.scenario.scenarioName),
                        React.createElement("p", { className: "text-muted font-14 mb-3" }),
                        React.createElement("div", null,
                            React.createElement("div", { className: "alert alert-success", role: "alert" },
                                React.createElement("p", { style: { fontSize: 15, fontWeight: 400 } }, this.props.scenario.description)))))),
            this.props.scenarioId !== 1 &&
                React.createElement(React.Fragment, null,
                    React.createElement(reactstrap_1.Row, null,
                        React.createElement(reactstrap_1.Card, null,
                            React.createElement(reactstrap_1.CardBody, null,
                                React.createElement("h4", { className: "card-title", style: { fontWeight: 600, paddingTop: 20 } }, "Description of the variables"),
                                React.createElement("p", { className: "text-muted font-14 mb-3" }, "Additional description of variables selected for simulation."),
                                React.createElement("div", null, this.state.divVarDescription ? this.state.divVarDescription : '')))),
                    React.createElement(reactstrap_1.Row, null,
                        React.createElement(reactstrap_1.Card, null,
                            React.createElement("div", { className: "col-12" },
                                React.createElement("div", { className: "d-flex flex-wrap" },
                                    React.createElement("div", null,
                                        React.createElement("h4", { className: "card-title", style: { fontWeight: 600, paddingTop: 20 } }, "Variables"),
                                        React.createElement(reactstrap_1.CardSubtitle, null, "Select the input for the simulation (default values are shown)."),
                                        React.createElement("p", null)),
                                    React.createElement("div", { className: "ml-auto" },
                                        React.createElement("div", { className: "d-flex no-block align-items-center justify-content-center" },
                                            React.createElement("div", null,
                                                React.createElement("h6", { className: "text-success" },
                                                    React.createElement("i", { className: "font-10 mr-2" }))),
                                            React.createElement("div", { className: "ml-3" },
                                                React.createElement("h6", { className: "text-info" },
                                                    React.createElement("i", { className: "font-10 mr-2" }))))))),
                            React.createElement(reactstrap_1.CardBody, null,
                                React.createElement(reactstrap_1.Row, null,
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("div", { className: "row" },
                                            React.createElement("div", { className: "col-sm-12 px-5" }, this.state.listVariables && this.state.listVariables.filter(function (t) { return (t.type === 'Switch'); }).map(function (variable) { return (React.createElement("div", { key: 'switch_' + variable.variableId },
                                                React.createElement(core_1.FormGroup, { row: true },
                                                    React.createElement(FormControlLabel_1.default, { labelPlacement: "end", control: React.createElement(Switch_1.default, { color: "primary", onChange: function (evt) { return _this.handleChange(variable, evt); }, checked: _this.state.switches[variable.variableId], inputProps: { 'aria-label': 'checkbox with default color' } }), label: variable.nameUi, "data-toggle": "tooltip", "data-placement": "right", title: variable.toolTipUi })))); }))))),
                                React.createElement(reactstrap_1.Row, null,
                                    React.createElement("div", { className: "col-12", style: { display: "none" } },
                                        React.createElement("div", { className: "d-flex flex-wrap" },
                                            React.createElement("div", null,
                                                React.createElement("h4", { className: "card-title", style: { fontWeight: 600, paddingTop: 20 } }, "Individual metrics performance"),
                                                React.createElement(reactstrap_1.CardSubtitle, null, "Individual metrics performance")),
                                            React.createElement("div", { className: "ml-auto" },
                                                React.createElement("div", { className: "d-flex no-block align-items-center justify-content-center" },
                                                    React.createElement("div", null,
                                                        React.createElement("h6", { className: "text-success" },
                                                            React.createElement("i", { className: "font-10 mr-2" }))),
                                                    React.createElement("div", { className: "ml-3" },
                                                        React.createElement("h6", { className: "text-info" },
                                                            React.createElement("i", { className: "font-10 mr-2" }))))))),
                                    React.createElement("div", { className: "col-12" },
                                        React.createElement("div", { className: "row" },
                                            this.state.listVariables && this.state.listVariables.filter(function (t) { return (t.type === 'Dropdown'); }).map(function (variable) { return (React.createElement("div", { className: "col-sm-" + (variable.fullRow ? '12' : '6') + " px-5", key: 'sliderId-' + variable.variableId },
                                                React.createElement("h4", { "data-toggle": "tooltip", "data-placement": "right", title: variable.toolTipUi, style: { marginLeft: -20, paddingTop: 25, paddingBottom: 20 } },
                                                    variable.nameUi,
                                                    React.createElement("i", { style: { cursor: 'pointer', paddingLeft: 20 }, className: "mdi mdi-information-outline", onClick: function (e) {
                                                            if (_this.myRef[variable.variableId]) {
                                                                _this.myRef[variable.variableId].scrollIntoView(true);
                                                                window.scrollBy(0, -140);
                                                            }
                                                        } })),
                                                React.createElement(core_1.RadioGroup, { key: "radio_" + variable.variableId, row: true, "aria-label": "position", name: "position", defaultValue: variable.initialValue.toString(), value: _this.state.variableValueState[variable.variableId].toString() || variable.initialValue.toString(), onChange: function (e, newVal) {
                                                        _this.updateVariable(variable, +newVal);
                                                    } }, variableMarksState[variable.variableId].map(function (mark, index) { return (React.createElement(FormControlLabel_1.default, { key: "radio_" + variable.variableId + "_" + index, value: mark.value, control: React.createElement(core_1.Radio, { color: "primary" }), label: mark.label })); })))); }),
                                            this.state.listVariables && this.state.listVariables.filter(function (t) { return (t.type === 'Continuous'); }).map(function (variable) { return (React.createElement("div", { className: "col-sm-" + (variable.fullRow ? '12' : '6') + " px-5", key: 'sliderId-' + variable.variableId },
                                                React.createElement("h4", { "data-toggle": "tooltip", "data-placement": "right", title: variable.toolTipUi, style: { marginLeft: -20, paddingTop: 25, paddingBottom: 20 } },
                                                    variable.nameUi,
                                                    React.createElement("i", { style: { cursor: 'pointer', paddingLeft: 20 }, className: "mdi mdi-information-outline", onClick: function (e) {
                                                            if (_this.myRef[variable.variableId]) {
                                                                _this.myRef[variable.variableId].scrollIntoView(true);
                                                                window.scrollBy(0, -140);
                                                            }
                                                        } })),
                                                React.createElement(core_1.Slider, { key: "slider_" + variable.variableId, defaultValue: variable.initialValue, value: _this.state.variableValueState[variable.variableId], "aria-labelledby": "discrete-slider-small-steps", step: variable.step, min: variable.mininum, max: variable.maximum, valueLabelDisplay: "auto", marks: variableMarksState[variable.variableId], onChangeCommitted: function (e, newVal) {
                                                        _this.updateVariable(variable, newVal);
                                                    } }))); })))),
                                React.createElement("p", { className: "text-muted font-14 mb-3", style: { marginTop: 20 } },
                                    React.createElement("button", { type: "submit", className: "btn btn-info", 
                                        //disabled={this.state.}
                                        onClick: function () {
                                            _this.runModel();
                                        } }, "Run simulation"))))),
                    React.createElement(reactstrap_1.Row, null,
                        React.createElement(reactstrap_1.Card, null,
                            React.createElement(reactstrap_1.CardBody, null,
                                React.createElement("h4", { className: "card-title", style: { fontWeight: 600, paddingTop: 20 } }, "History"),
                                React.createElement("p", { className: "text-muted font-14 mb-3" }, "Simulation history (stored in your browser\u2019s cache)"),
                                React.createElement("p", { className: "text-muted font-14 mb-3" },
                                    React.createElement("button", { type: "submit", className: "btn btn-info", onClick: function () {
                                            LocalstoreUtil_1.localStoreHelper.clearHistory();
                                            //localStoreHelper.clearResultGraphs(true);
                                            //renderGraphs(this.props.scenarioId, this.state.variableValueState, this.props.scenario.scenarioName, this.state.listVariables, false, false);
                                            _this.fetchData();
                                        } }, "Clear history")),
                                React.createElement(GridHistory_1.default, { onLoadScenario: this.loadScenario.bind(this), clearHistory: this.state.clearHistory })))))));
    };
    Variables.prototype.loadScenario = function (historyData) {
        var newvars = __assign({}, historyData.variablesValues);
        var variableValueNew = __assign({}, this.state.variableValueState);
        var switchesNew = __assign({}, this.state.switches);
        var varId = -1;
        Object.keys(newvars).forEach(function (key) {
            varId = +key;
            variableValueNew[varId] = newvars[varId];
            switchesNew[varId] = (newvars[varId] === 1 ? true : false);
        });
        this.setState({
            variableValueState: variableValueNew,
            switches: switchesNew
        });
        renderGraphs(historyData.scenarioId, historyData.variablesValues, this.props.scenario.scenarioName, historyData.listVariables);
    };
    Variables.prototype.handleChange = function (variable, event) {
        var switchesTemp = __assign({}, this.state.switches);
        var variableValue = __assign({}, this.state.variableValueState);
        variableValue[variable.variableId] = event.target.checked ? 1 : 0;
        switchesTemp[variable.variableId] = event.target.checked;
        this.setState({ switches: switchesTemp, variableValueState: variableValue });
        ////if new variables are equal to baseline do not update graphs and history
        //if (!isEquivalent(variableValue, variableValueBaseline)) {
        //    renderGraphs(this.props.scenarioId, variableValue, this.props.scenario.scenarioName, this.state.listVariables);
        //    this.props.updateHistory();
        //}
    };
    ;
    Variables.prototype.updateVariable = function (variable, newValue) {
        var variableValue = __assign({}, this.state.variableValueState);
        variableValue[variable.variableId] = newValue;
        this.setState({ variableValueState: variableValue });
    };
    Variables.prototype.runModel = function () {
        var flagNotBaseline = !isEquivalent(this.state.variableValueState, variableValueBaseline);
        renderGraphs(this.props.scenarioId, this.state.variableValueState, this.props.scenario.scenarioName, this.state.listVariables, flagNotBaseline, flagNotBaseline);
        //if new variables are equal to baseline do not update graphs and history
        if (flagNotBaseline) {
            this.props.updateHistory();
        }
    };
    Variables.prototype.render = function () {
        return this.renderForm();
    };
    return Variables;
}(React.Component));
exports.Variables = Variables;
function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    // If we made it this far, objects
    // are considered equivalent
    return true;
}
function renderGraphs(scenarioId, variableValue, scenarioName, listVariables, flagAdd2History, flagAdd2Results) {
    if (flagAdd2History === void 0) { flagAdd2History = true; }
    if (flagAdd2Results === void 0) { flagAdd2Results = true; }
    if (flagAdd2History)
        LocalstoreUtil_1.localStoreHelper.addModelHistory(scenarioId, variableValue, scenarioName, listVariables);
    ReactDOM.render(React.createElement(Graphs_1.Graphs, { scenarioId: scenarioId, variableValue: variableValue, listVariables: listVariables, add2Results: flagAdd2Results }), document.getElementById("divGraphs"));
}
