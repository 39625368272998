"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var styles_1 = require("@material-ui/core/styles");
var Paper_1 = require("@material-ui/core/Paper");
var Table_1 = require("@material-ui/core/Table");
var TableBody_1 = require("@material-ui/core/TableBody");
var TableCell_1 = require("@material-ui/core/TableCell");
var TableContainer_1 = require("@material-ui/core/TableContainer");
var TableHead_1 = require("@material-ui/core/TableHead");
var TablePagination_1 = require("@material-ui/core/TablePagination");
var TableRow_1 = require("@material-ui/core/TableRow");
var LocalstoreUtil_1 = require("./LocalstoreUtil");
var IconButton_1 = require("@material-ui/core/IconButton");
var KeyboardArrowDown_1 = require("@material-ui/icons/KeyboardArrowDown");
var KeyboardArrowUp_1 = require("@material-ui/icons/KeyboardArrowUp");
var Collapse_1 = require("@material-ui/core/Collapse");
var Typography_1 = require("@material-ui/core/Typography");
var Box_1 = require("@material-ui/core/Box");
var OpenInBrowserOutlined_1 = require("@material-ui/icons/OpenInBrowserOutlined");
var Tooltip_1 = require("@material-ui/core/Tooltip");
var columns = [
    { id: 'id', label: 'Details', minWidth: 5 },
    { id: 'name', label: 'Name', minWidth: 300 },
];
var useStyles = styles_1.makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});
function listHistory() {
    var history = LocalstoreUtil_1.localStoreHelper.readModelHistory();
    var listOptions = [];
    if (history && history.length > 0) {
        history.forEach(function (h) {
            listOptions.push(__assign({ label: h.runName, value: h.scenarioId.toString() }, h));
        });
    }
    return listOptions;
}
var rows = [];
var GridHistory = function (_a) {
    var onLoadScenario = _a.onLoadScenario;
    rows = listHistory();
    var classes = useStyles();
    var _b = React.useState(0), page = _b[0], setPage = _b[1];
    var _c = React.useState(20), rowsPerPage = _c[0], setRowsPerPage = _c[1];
    var _d = React.useState(false), open = _d[0], setOpen = _d[1];
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    var useRowStyles = styles_1.makeStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
    });
    function Row(props) {
        var row = props.row;
        var _a = React.useState(false), open = _a[0], setOpen = _a[1];
        var classes = useRowStyles();
        var VariableHash = {};
        return (React.createElement(React.Fragment, null,
            React.createElement(React.Fragment, null,
                React.createElement(TableRow_1.default, { className: classes.root },
                    React.createElement(TableCell_1.default, null,
                        React.createElement(Tooltip_1.default, { title: open ? "Collapse details" : "Show details" },
                            React.createElement(IconButton_1.default, { "aria-label": "expand row", size: "small", onClick: function () { return setOpen(!open); } }, open ? React.createElement(KeyboardArrowUp_1.default, null) : React.createElement(KeyboardArrowDown_1.default, null)))),
                    React.createElement(TableCell_1.default, { component: "th", scope: "row" },
                        React.createElement(Typography_1.default, { variant: "h6" },
                            row.runName,
                            React.createElement(Tooltip_1.default, { title: "Reload the scenario" },
                                React.createElement(IconButton_1.default, { "aria-label": "expand row", size: "small", onClick: function (e) {
                                        onLoadScenario(row);
                                    }, style: { marginLeft: 20 } },
                                    React.createElement(OpenInBrowserOutlined_1.default, null)))))),
                React.createElement(TableRow_1.default, null,
                    React.createElement(TableCell_1.default, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: 6 },
                        React.createElement(Collapse_1.default, { in: open, timeout: "auto", unmountOnExit: true },
                            React.createElement(Box_1.default, { margin: 2, marginBottom: 5 },
                                React.createElement(Typography_1.default, { variant: "h6", gutterBottom: true, component: "div" }, "Scenario details"),
                                (row.variablesValues && row.variablesValues !== null) &&
                                    React.createElement(Table_1.default, { size: "small", "aria-label": "purchases" },
                                        React.createElement(TableHead_1.default, null,
                                            React.createElement(TableRow_1.default, null,
                                                React.createElement(TableCell_1.default, null, "Variable"),
                                                React.createElement(TableCell_1.default, null, "Value"),
                                                React.createElement(TableCell_1.default, null, "Unit"))),
                                        React.createElement(TableBody_1.default, null, Object.keys(row.variablesValues).map(function (key) { return (React.createElement(TableRow_1.default, { key: key },
                                            React.createElement(TableCell_1.default, { component: "th", scope: "row" }, row.listVariables.find(function (v) { return v.variableId === +key; }).nameUi),
                                            React.createElement(TableCell_1.default, { align: "center" }, row.variablesValues[+key]),
                                            React.createElement(TableCell_1.default, { align: "center" }, [(row.listVariables.find(function (v) { return v.variableId === +key; }))].map(function (v) { return (v.unit !== 'dmnl' ? v.unit : ''); })))); }))))))))));
    }
    return (React.createElement(Paper_1.default, { className: classes.root },
        React.createElement(TableContainer_1.default, { className: classes.container },
            React.createElement(Table_1.default, { stickyHeader: true, "aria-label": "sticky table" },
                React.createElement(TableHead_1.default, null,
                    React.createElement(TableRow_1.default, null, columns.map(function (column) { return (React.createElement(TableCell_1.default, { key: column.id, align: column.align, style: { minWidth: column.minWidth } }, column.label)); }))),
                React.createElement(TableBody_1.default, null, rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(function (row) {
                    return (React.createElement(Row, { key: row.runName, row: row }));
                    { /*
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            <TableCell>
                                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
               x             </TableCell>
                            {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                    </TableCell>
                                );
                            })}
                        </TableRow>*/
                    }
                })))),
        React.createElement(TablePagination_1.default, { rowsPerPageOptions: [20, 50, { value: -1, label: 'All' }], component: "div", count: rows.length, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage })));
};
exports.default = GridHistory;
