"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MainPage = void 0;
var React = require("react");
var Variables_1 = require("./Variables");
var ReactDOM = require("react-dom");
var react_select_1 = require("react-select");
var Button_1 = require("@material-ui/core/Button");
var Dialog_1 = require("@material-ui/core/Dialog");
var DialogActions_1 = require("@material-ui/core/DialogActions");
var DialogContent_1 = require("@material-ui/core/DialogContent");
var DialogContentText_1 = require("@material-ui/core/DialogContentText");
var DialogTitle_1 = require("@material-ui/core/DialogTitle");
var TextField_1 = require("@material-ui/core/TextField");
var LocalstoreUtil_1 = require("./LocalstoreUtil");
var Recaptcha = require("react-recaptcha");
var reactstrap_1 = require("reactstrap");
//#region "My Region"
var groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 15,
    FontWeight: 'bold',
};
var groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 15,
    FontWeight: 'bold',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.1667em 0.5em',
    TextAlign: 'center',
};
var formatGroupLabel = function (data) { return (React.createElement("div", { style: groupStyles },
    React.createElement("span", null, data.label))); };
//#endregion "My Region"
var aboutCGE = React.createElement(React.Fragment, null,
    React.createElement("h4", { className: "mb-3" }, "About the Global Change Explorer"),
    React.createElement("p", null, "Welcome to the Global Change Explorer (GCE), an interactive tool for investigating complexities of global change. The GCE is using ANEMI simulation model developed at the University of Western Ontario by the research group of Professor Slobodan P. Simonovic. The researchers involved with the development of GCE and ANEMI include:  S.P. Simonovic, G. McBean,  J. B. Davies,  J. MacGee, E.G.R. Davies, M.K. Akhtar, J. Wibe, P.A. Breach and A. Schardong."),
    React.createElement("p", null, "ANEMI is named after the Greek  ANEMOI  gods of the four winds: Boreas the North-Wind (bringing the cold breath of winter), Zephryos the West (the god of spring breezes), Notos  the South (the god of summer rain-storms), and Euros the East (associated with the season of autumn)."),
    React.createElement("p", null, "ANEMI simulates system dynamics to offer information on Earth's dynamic processes and the behaviours that instigate change. The main thrust of ANEMI is to explicitly integrate various sectors (natural, physical and socioeconomic) into a single model, providing effective consideration of the high level feedback relationships between physical environment and social adaptation. At lower levels this relationship ends in thousands of feedbacks between various model sectors and variables. The ANEMI model is an integrated assessment model of global change that emphasizes the role of water resources."),
    React.createElement("p", null, "The model sectors that comprise the ANEMI3 (the current version of the model) are that of the climate system, carbon, nutrient, and hydrologic cycles, population dynamics, land use, food production, sea level rise, energy production, global economy, persistent pollution, water demand, and water supply development. The ANEMI3 model is developed using Vensim system dynamics simulation environment. The entire model code is archived using Zenodo (https://doi.org/10.5281/zenodo.4025424) and available to everyone. Details on how to  run the model, modify inputs, and view the outputs in graphical or table formats are provided in the repository. "),
    React.createElement("p", null, "The GCE is designed to allow use of ANEMI3 to simulate various future scenarios related to five main themes: climate change; population dynamics; food production; water quality and water quantity. The users are presented with the opportunity to ask different questions, select simulation runs, and evaluate model outputs."),
    React.createElement("p", null, "Visit the resources provided under the Help menu to learn more, or just start simulating the model. We will be very much interested in your feedback. Contact us at simonovic@uwo.ca."),
    React.createElement("p", null,
        "Citation: Simonovic, S.P., E.Davis, K. Akhtar, P. Breach and A. Schardong (2020), ",
        React.createElement("i", null, " Global Change Explorer (GCE): An Interactive Tool for Investigating Complexities of Global Change \u2013 ver 1.0"),
        ", Western University Facility for Intelligent Decision Support, open access",
        React.createElement("a", { href: "https://globalchange-uwo.ca", target: "blank" }, "https://globalchange-uwo.ca"),
        "."),
    React.createElement("p", null, "Release Nov 25, 2020"));
var StringFormat = function (str) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return str.replace(/{(\d+)}/g, function (match, index) { return args[index] || ''; });
};
var defaultScenarioId = 1;
var MainPage = /** @class */ (function (_super) {
    __extends(MainPage, _super);
    function MainPage(props) {
        var _this = _super.call(this, props) || this;
        _this.emailShare = '';
        _this.reCaptcha = '';
        _this.buttonCloseMenu = null;
        _this.filterScenarios = function (inputValue) {
            return _this.state.groupedOptions
                .filter(function (i) {
                return i.label.toLowerCase().includes(inputValue.toLowerCase());
            });
        };
        _this.state = {
            divGroups: null,
            divScenarios: null,
            loading: false,
            selGroup: null,
            selectedScenario: null,
            groupedOptions: [],
            openDialog: false,
            openDialogShare: false,
            openDialogAboutCGE: true,
            listOptionsHistory: null,
            updateSlidersFromHistory: null,
            shareError: false,
            confirmResetVarDialog: false
        };
        var restObjectId = window.ResultsObject;
        if (restObjectId != -1) {
            defaultScenarioId = window.ScenarioId;
        }
        return _this;
    }
    MainPage.prototype.componentDidMount = function () {
        var _this = this;
        this.fetchData();
        fetch("/api/scenarios/" + defaultScenarioId).then(function (res) { return res.json(); })
            .then(function (s) {
            LocalstoreUtil_1.localStoreHelper.clearHistory();
            LocalstoreUtil_1.localStoreHelper.clearResultGraphs();
            _this.setState({
                selectedScenario: __assign({ label: s.scenarioName, value: s.scenarioId }, s)
            });
        });
        this.loadComboBox();
        this.updateHistory();
    };
    MainPage.prototype.fetchData = function () {
        var _this = this;
        fetch('/api/scenariogroups').then(function (res) { return res.json(); })
            .then(function (result) {
            _this.loadScenarioGroups(result);
        });
    };
    MainPage.prototype.loadScenarioGroups = function (result) {
        var _this = this;
        var listDiv = result.map(function (res, index) {
            //if (res.scenarioGroupId !== 0)
            return (React.createElement("div", { className: "card mb-1", key: "id" + index },
                React.createElement("div", { className: "card-header", role: "tab", id: "headingTwo" + index },
                    React.createElement("h5", { className: "mb-0" },
                        React.createElement("a", { className: "collapsed", "data-toggle": "collapse", "data-parent": "#accordion", href: "#collapseTwo" + index, "aria-expanded": "false", "aria-controls": "collapseTwo" + index, onClick: function (ev) { return _this.fetchScenarios(res); } }, res.scenarioGroupName)))));
        });
        this.setState({
            loading: false,
            divGroups: listDiv
        });
        if (result && result[0])
            this.fetchScenarios(result[0]);
    };
    MainPage.prototype.loadScenarios = function (result) {
        var _this = this;
        var listDiv = result.map(function (res, index) {
            return (React.createElement("li", { key: 'li-' + index },
                React.createElement("a", { href: "#", onClick: function (e) {
                        if (_this.buttonCloseMenu) {
                            _this.buttonCloseMenu.click();
                        }
                        LocalstoreUtil_1.localStoreHelper.clearHistory();
                        LocalstoreUtil_1.localStoreHelper.clearResultGraphs();
                        _this.setState({
                            selectedScenario: __assign({ label: res.scenarioName, value: res.scenarioId }, res)
                        });
                        _this.updateHistory();
                    } },
                    React.createElement("i", { className: "fa fa-check text-success" }),
                    ' ' + res.scenarioName)));
        });
        this.setState({
            loading: false,
            divScenarios: listDiv
        });
    };
    MainPage.prototype.fetchScenarios = function (scenarioGroup) {
        var _this = this;
        this.setState({
            selGroup: scenarioGroup
        });
        fetch("/api/scenariogroups/" + scenarioGroup.scenarioGroupId).then(function (res) { return res.json(); })
            .then(function (listScenarios) {
            _this.loadScenarios(listScenarios);
        });
    };
    MainPage.prototype.loadComboBox = function () {
        var _this = this;
        fetch("/api/scenarios").then(function (res) { return res.json(); })
            .then(function (listScenarios) {
            var groupedOptions = {};
            listScenarios.forEach(function (s) {
                if (!groupedOptions[s.scenarioGroupName])
                    groupedOptions[s.scenarioGroupName] = [];
                groupedOptions[s.scenarioGroupName].push(__assign({ label: s.scenarioName + " (Q: " + s.questionName + ")", value: s.scenarioId }, s));
            });
            var finalGroup = [];
            Object.keys(groupedOptions).forEach(function (key) {
                finalGroup.push({
                    label: key,
                    options: groupedOptions[key]
                });
            });
            _this.setState({
                groupedOptions: finalGroup
            });
        });
    };
    MainPage.prototype.sendEmail = function () {
        var _this = this;
        var requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                emailAddress: this.emailShare,
                reCaptcha: this.reCaptcha,
                resultObjectId: sessionStorage.getItem('ResultObjectId')
            })
        };
        fetch('/api/sendEmail', requestOptions)
            .then(function (res) {
            res.json().then(function (r) { return console.log(r); });
            if (res.status !== 200)
                _this.setState({
                    shareError: true
                });
            else
                _this.setState({
                    openDialogShare: false,
                    shareError: false
                });
        }, function (error) {
            _this.setState({
                shareError: true
            });
        });
    };
    MainPage.prototype.exportData = function (resultsId) {
        var _this = this;
        var filename = "ANEMI-DataExport-" + resultsId + ".xlsx";
        this.setState({
            loading: true
        });
        fetch("/api/scenarioExport/" + resultsId)
            .then(function (response) { return response.blob(); })
            .then(function (blob) {
            // 2. Create blob link to download
            var url = window.URL.createObjectURL(new Blob([blob]));
            var link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "Download-" + filename);
            // 3. Append to html page
            document.body.appendChild(link);
            // 4. Force download
            link.click();
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link);
            _this.setState({
                loading: false
            });
        })
            .catch(function (error) {
            _this.setState({
                loading: false
            });
        });
    };
    MainPage.prototype.updateHistory = function () {
        var history = LocalstoreUtil_1.localStoreHelper.readModelHistory();
        if (history && history.length > 0) {
            var listOptions_1 = [];
            history.forEach(function (h) {
                listOptions_1.push(__assign({ label: h.runName, value: h.scenarioId.toString() }, h));
            });
            this.setState({
                listOptionsHistory: listOptions_1
            });
        }
    };
    MainPage.prototype.forceResetFunc = function (flag) {
        this.setState({
            confirmResetVarDialog: false
        });
    };
    MainPage.prototype.render = function () {
        return (this.renderMain());
    };
    MainPage.prototype.renderMain = function () {
        var _this = this;
        return React.createElement(React.Fragment, null,
            React.createElement("header", { className: "topbar" },
                React.createElement("nav", { className: "navbar top-navbar navbar-expand-lg navbar-dark" },
                    React.createElement("div", { className: "navbar-header" },
                        React.createElement("a", { className: "nav-toggler waves-effect waves-light d-block d-lg-none", href: "#" },
                            React.createElement("i", { className: "ti-menu ti-close" })),
                        React.createElement("a", { className: "navbar-brand", href: "/" },
                            React.createElement("b", { className: "logo-icon" },
                                React.createElement("img", { src: "~/images/logo-icon.png", alt: "CGE", className: "dark-logo" })),
                            React.createElement("span", { className: "logo-text" },
                                "Global Change Explorer",
                                React.createElement("img", { src: "~/images/logo-text.png", alt: "Western", className: "dark-logo" }))),
                        React.createElement("a", { className: "topbartoggler d-block d-lg-none waves-effect waves-light", href: "#", "data-toggle": "collapse", "data-target": "#navbarSupportedContent", "aria-controls": "navbarSupportedContent", "aria-expanded": "false", "aria-label": "Toggle navigation" },
                            React.createElement("i", { className: "ti-more" }))),
                    React.createElement("div", { className: "navbar-collapse collapse", id: "navbarSupportedContent" },
                        React.createElement("ul", { className: "navbar-nav mr-auto", style: { width: '100%' } },
                            React.createElement("li", { className: "nav-item dropdown mega-dropdown" },
                                React.createElement("a", { className: "nav-link dropdown-toggle waves-effect waves-dark", href: "", "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false" },
                                    React.createElement("i", { className: "mdi mdi-view-grid" }),
                                    " Scenarios"),
                                React.createElement("div", { className: "dropdown-menu scale-up-left", id: "divMegaMenuScenario" },
                                    React.createElement("ul", { className: "mega-dropdown-menu row p-0 m-0 list-inline" },
                                        React.createElement("li", { className: "col-lg-6 mb-4" },
                                            React.createElement("h4", { className: "mb-3" }, "Scenarios"),
                                            React.createElement("div", { id: "accordion", className: "nav-accordion", role: "tablist", "aria-multiselectable": "true" }, this.state.divGroups ? this.state.divGroups : null),
                                            React.createElement("button", { type: "submit", className: "btn btn-info", "data-toggle": "dropdown", style: { marginTop: 10 }, ref: function (input) { return _this.buttonCloseMenu = input; } }, "Close")),
                                        React.createElement("li", { className: "col-lg-6 col-xlg-6 mb-4" },
                                            React.createElement("h4", { className: "mb-3" },
                                                "Scenarios runs: ",
                                                this.state.selGroup ? React.createElement("strong", null, this.state.selGroup.scenarioGroupName) : ''),
                                            React.createElement("ul", { className: "list-style-none", id: "lstScenarios", style: { fontSize: 16, fontWeight: 600 } }, this.state.divScenarios ? this.state.divScenarios : null))))),
                            React.createElement("li", { style: { paddingTop: 10, paddingLeft: 20, width: '80%' } }, this.state.groupedOptions &&
                                React.createElement(react_select_1.default, { cacheOptions: true, defaultValue: this.state.selectedScenario ? this.state.selectedScenario.scenarioName : null, value: this.state.selectedScenario ? this.state.selectedScenario : null, isClearable: true, isSearchable: true, defaultOptions: true, loadOptions: this.filterScenarios, placeholder: 'Type a question or keyword to find a scenario', options: this.state.groupedOptions, formatGroupLabel: formatGroupLabel, onChange: function (scenario) {
                                        LocalstoreUtil_1.localStoreHelper.clearHistory();
                                        LocalstoreUtil_1.localStoreHelper.clearResultGraphs();
                                        _this.setState({
                                            selectedScenario: scenario
                                        });
                                        _this.updateHistory();
                                    }, className: 'selectCombo', classNamePrefix: 'react-select' }))),
                        React.createElement("ul", { className: "navbar-nav" },
                            React.createElement("li", { className: "nav-item dropdown mega-dropdown" },
                                React.createElement("a", { className: "nav-link dropdown-toggle waves-effect waves-dark", href: "", "data-toggle": "dropdown", "aria-haspopup": "true", "aria-expanded": "false" },
                                    React.createElement("i", { className: "mdi mdi-help" }),
                                    " Help"),
                                React.createElement("div", { className: "dropdown-menu dropdown-menu-right  scale-up", style: { maxHeight: 700 } },
                                    React.createElement("ul", { className: "mega-dropdown-menu row p-0 m-0 list-inline" },
                                        React.createElement("div", { className: "col-lg-12 mb-4" }, aboutCGE),
                                        React.createElement("div", { className: "col-lg-12 mb-4" },
                                            React.createElement("h4", { className: "mb-3" }, "User manual"),
                                            React.createElement("p", null,
                                                React.createElement("a", { href: "./manuals/108.pdf", target: "_blank" }, "Breach, P.A. and S. P. Simonovic (2020). ANEMI 3: Tool for investigating impacts of global change. Water Resources Research Report no. 108, Facility for Intelligent Decision Support, Department of Civil and Environmental Engineering, The University of Western Ontario, London, Ontario, Canada, 134 pages. ISBN: (print) 978-0-7714-3145-6 ; (online)  978-0-7714-3146-3.")),
                                            React.createElement("h4", { className: "mb-3" }, "Other resources"),
                                            React.createElement("p", null,
                                                React.createElement("a", { href: "./manuals/J155_ANEMI_Model_IER_2010.pdf", target: "_blank" }, "Davies, E.G.R, and S.P. Simonovic, (2010) \"ANEMI: A New Model for Integrated Assessment of Global Change\", the Interdisciplinary Environmental Review special issue on Climate Change, 11(2/3):127-161.")),
                                            React.createElement("p", null,
                                                React.createElement("a", { href: "./manuals/J159_WaterClimateFeedback_AWR_2011.pdf", target: "_blank" }, "Davies, E.G.R, and S.P. Simonovic, (2011) \"Global water resources modeling with an integrated model of the social-economic-environmental system\", Advances in Water Resources, 34:684-700.")),
                                            React.createElement("p", null,
                                                React.createElement("a", { href: "./manuals/J176_ANEMI_2_JEMS_2013.pdf", target: "_blank" }, "Akhtar, M.K., J., Wibe, S. P. Simonovic, and J., MacGee, (2013) \"Integrated Assessment Model of Society-Biosphere-Climate-Economy-Energy System\", Environmental Modelling & Software, 49:1-21.")),
                                            React.createElement("p", null,
                                                React.createElement("a", { href: "./manuals/J226_ANEMI2_CDN_IJGW_2018.pdf", target: "_blank" }, "Akhtar, M.K., S.P. Simonovic, J. Wibe, and J. MacGee, (2018) \"Future realities of climate change impacts: An integrated assessment study of Canada, International Journal of Global Warming, 17(1):59-88.")),
                                            React.createElement("p", null,
                                                React.createElement("a", { href: "./manuals/J220_Energy_Patrick_EM_2018.pdf", target: "_blank" }, "Breach, P. A., and S.P. Simonovic, (2018) \"Wastewater treatment energy recovery potential for adaptation to global change: an integrated assessment\", Environmental Management, 61:624-636.")),
                                            React.createElement("p", null,
                                                React.createElement("a", { href: "./manuals/J249_ANEMI3_WATER_2020.pdf", target: "_blank" }, "Simonovic, S.P and Breach, P.A. (2020) \"The Role of Water Supply Development in the Earth System\". Water 2020, 12:3349."))),
                                        React.createElement("div", { className: "col-lg-12 mb-4" },
                                            React.createElement("div", { className: "row" },
                                                React.createElement("div", { className: "col-lg-2 col-md-2" },
                                                    React.createElement("img", { src: "images/NSERC.jpg", alt: "NSERC", height: "50px" })),
                                                React.createElement("div", { className: "col-lg-2 col-md-2" },
                                                    " ",
                                                    React.createElement("img", { alt: "Western University", className: "Western University", height: "50px", src: "https://www.uwo.ca/img/homepage/2017/ttl-westernlogo.svg" })))),
                                        React.createElement("div", { className: "col-lg-12 mb-4" },
                                            React.createElement("p", { style: { fontSize: 14 } },
                                                "Copyright ",
                                                React.createElement("span", { dangerouslySetInnerHTML: { "__html": "&copy;" } }),
                                                React.createElement("a", { href: "https://www.eng.uwo.ca/research/iclr/fids/", target: "_blank" }, "FIDS"),
                                                " - Western University - 2020")),
                                        React.createElement("div", { className: "col-lg-12 mb-4" },
                                            React.createElement("button", { type: "submit", className: "btn btn-info", "data-toggle": "dropdown", style: { marginTop: 10 } }, "Close"))))))))),
            React.createElement("aside", { className: "left-sidebar" },
                React.createElement("div", { className: "scroll-sidebar" },
                    React.createElement("nav", { className: "sidebar-nav" },
                        React.createElement("ul", { id: "sidebarnav", style: { fontSize: 18 } },
                            React.createElement("li", { className: "sidebar-item" },
                                React.createElement("a", { className: "nav-link  waves-effect waves-dark", href: "#", "aria-expanded": "false", onClick: function (e) {
                                        _this.setState({ openDialog: true });
                                    } },
                                    React.createElement("i", { className: "mdi mdi-gauge" }),
                                    React.createElement("span", { className: "hide-menu" }, " Reset values"))),
                            React.createElement("li", { className: "sidebar-item" },
                                React.createElement("a", { className: "nav-link  waves-effect waves-dark", href: "#", "aria-expanded": "false", onClick: function (e) {
                                        _this.setState({ openDialogShare: true });
                                    } },
                                    React.createElement("i", { className: "mdi mdi-share-variant" }),
                                    " Share")),
                            React.createElement("li", { className: "sidebar-item" },
                                React.createElement("a", { className: "nav-link  waves-effect waves-dark", href: "#", "aria-expanded": "false", onClick: function (e) {
                                        _this.exportData(sessionStorage.getItem('ResultObjectId'));
                                    } },
                                    React.createElement("i", { className: "mdi mdi-export" }),
                                    " Export")),
                            React.createElement("li", { className: "sidebar-item", style: { display: 'none' } },
                                React.createElement("a", { className: "nav-link  waves-effect waves-dark", href: "#", "aria-expanded": "false", onClick: function (e) {
                                        _this.setState({ openDialogShare: true });
                                    } },
                                    React.createElement("i", { className: "mdi mdi-book-open-page-variant" }),
                                    " Public scenarios")),
                            React.createElement("li", { className: "", style: { width: '40%', paddingLeft: 40, cursor: "pointer" } }),
                            this.state.selectedScenario && 1 > 2 &&
                                React.createElement("li", { className: "sidebar-item" },
                                    React.createElement("div", { className: "sidebar-link waves-effect waves-dark active" },
                                        React.createElement("span", { className: "hide-menu", style: { paddingLeft: 20 } },
                                            this.state.selectedScenario.scenarioName,
                                            " "),
                                        React.createElement("i", { className: "mdi mdi-play" }))))))),
            React.createElement("div", { className: "page-wrapper" },
                React.createElement("div", { className: "container-fluid" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { id: "divGraphs", className: "col-lg-6 col-md-12" }),
                        React.createElement("div", { id: "divSliders", className: "col-lg-6 col-md-12" }, this.state.selectedScenario &&
                            React.createElement(Variables_1.Variables
                            //updateSlidersFromHistory={this.state.updateSlidersFromHistory}
                            , { 
                                //updateSlidersFromHistory={this.state.updateSlidersFromHistory}
                                scenarioId: this.state.selectedScenario.scenarioId, scenario: this.state.selectedScenario, forceResetFunc: this.forceResetFunc.bind(this), forceResetProp: this.state.confirmResetVarDialog, updateHistory: this.updateHistory.bind(this) })))),
                React.createElement("footer", { className: "footer", style: { background: '#fff', borderTop: '1px solid #e9ecef' } },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-lg-6 col-md-6" },
                            React.createElement("img", { src: "images/NSERC.jpg", alt: "NSERC", height: "60px" })),
                        React.createElement("div", { className: "col-lg-6 col-md-6" },
                            " ",
                            React.createElement("img", { alt: "Western University", className: "Western University", height: "60px", src: "https://www.uwo.ca/img/homepage/2017/ttl-westernlogo.svg" }))),
                    React.createElement("p", { style: { fontSize: 16 } },
                        "Copyright ",
                        React.createElement("span", { dangerouslySetInnerHTML: { "__html": "&copy;" } }),
                        React.createElement("a", { href: "https://www.eng.uwo.ca/research/iclr/fids/", target: "_blank" }, "FIDS"),
                        " - Western University - 2020"))),
            React.createElement(Dialog_1.default, { open: this.state.openDialog, onClose: function (e) {
                    _this.setState({
                        openDialog: false
                    });
                }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
                React.createElement(DialogTitle_1.default, { id: "alert-dialog-title" }, "Would you like to reset values?"),
                React.createElement(DialogContent_1.default, null,
                    React.createElement(DialogContentText_1.default, { id: "alert-dialog-description" }, "This will reset the values to the scenario's default and clear the results presented on the graphs.")),
                React.createElement(DialogActions_1.default, null,
                    React.createElement(Button_1.default, { onClick: function (e) {
                            _this.setState({
                                confirmResetVarDialog: true,
                                openDialog: false,
                            });
                        }, color: "primary", autoFocus: true }, "Yes"),
                    React.createElement(Button_1.default, { onClick: function (e) {
                            _this.setState({
                                openDialog: false
                            });
                        }, color: "default" }, "No"))),
            React.createElement(Dialog_1.default, { fullWidth: true, maxWidth: "lg", open: this.state.openDialogShare, onClose: function (e) {
                    _this.setState({
                        openDialogShare: false
                    });
                }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
                React.createElement(DialogTitle_1.default, { id: "alert-dialog-title" }, "Share current run"),
                React.createElement(DialogContent_1.default, null,
                    React.createElement(DialogContentText_1.default, { id: "alert-dialog-description" },
                        React.createElement("div", null, "Share the current values and variables with other researchers."),
                        this.state.shareError &&
                            React.createElement(reactstrap_1.Alert, null, "There was a problem sending your invitation. Try again.")),
                    React.createElement(TextField_1.default, { onChange: function (event) {
                            _this.emailShare = event.target.value;
                        }, autoFocus: true, margin: "dense", id: "name", label: "Email Address", type: "email", fullWidth: true, defaultValue: this.emailShare }),
                    React.createElement(Recaptcha, { elementID: "idRecaptcha", sitekey: "6LdaVs8ZAAAAAGWN4J-IicqozK2cYX2eWYer2wcn", render: "explicit", onloadCallback: function () {
                        }, verifyCallback: function (response) {
                            _this.reCaptcha = response;
                        } })),
                React.createElement(DialogActions_1.default, null,
                    React.createElement(Button_1.default, { onClick: function (e) {
                            _this.sendEmail();
                        }, color: "primary", autoFocus: true }, "Share"),
                    React.createElement(Button_1.default, { onClick: function (e) {
                            _this.setState({
                                openDialogShare: false,
                                shareError: false
                            });
                        }, color: "default" }, "Cancel"))),
            React.createElement(Dialog_1.default, { fullWidth: true, maxWidth: "lg", open: this.state.openDialogAboutCGE, onClose: function (e) {
                    _this.setState({
                        openDialogAboutCGE: false
                    });
                }, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
                React.createElement(DialogContent_1.default, null,
                    aboutCGE,
                    React.createElement("div", { className: "col-lg-12 mb-4" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-2 col-md-2" },
                                React.createElement("img", { src: "images/NSERC.jpg", alt: "NSERC", height: "40px" })),
                            React.createElement("div", { className: "col-lg-2 col-md-2" },
                                " ",
                                React.createElement("img", { alt: "Western University", className: "Western University", height: "40px", width: "", src: "https://www.uwo.ca/img/homepage/2017/ttl-westernlogo.svg" })))),
                    React.createElement("p", { style: { fontSize: 16 } },
                        "Copyright ",
                        React.createElement("span", { dangerouslySetInnerHTML: { "__html": "&copy;" } }),
                        React.createElement("a", { href: "https://www.eng.uwo.ca/research/iclr/fids/", target: "_blank" }, "FIDS"),
                        " - Western University - 2020")),
                React.createElement(DialogActions_1.default, null,
                    React.createElement("button", { type: "submit", className: "btn btn-info", "data-toggle": "dropdown", style: { marginTop: 10 }, onClick: function (e) {
                            _this.setState({
                                openDialogAboutCGE: false
                            });
                        } }, "Close"))));
    };
    return MainPage;
}(React.Component));
exports.MainPage = MainPage;
ReactDOM.render(React.createElement(MainPage, null), document.getElementById("main-wrapper"));
